import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"
import DataProtectionMeasures from "../components/data-protection-measures"
import FeatureColumn from "../components/feature-column"
import FeatureCard from "../components/feature-card"
import Testimonials from "../components/testimonials"
import FeatureObjectTypes from "../components/feature-object-types"
import DeploymentOptions from "../components/deployment-options"
import SuccessStoryCard from "../components/success-story-card"
import Demo from "../components/demo"
import MoreBlogPosts from "../components/more-blog-posts"
import WhyCelantur from "../components/why-celantur"
import SeagateCard from "../components/seagate-card"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faHandshake,
  faDatabase,
  faCogs,
  faBalanceScale,
  faLock,
  faEuroSign,
  faCar,
  faRoadCircleExclamation,
  faCarCrash,
  faMicrochip,
  faEarthEurope,
} from "@fortawesome/free-solid-svg-icons"

const Automotive = ({ data }) => (
  <Layout>
    <SEO
      title="Image and Video Anonymization for Autonomous Driving and Mobility | Celantur"
      description="Protect personal data in autonomous driving and mobility use-cases such as data collection with test vehicles, safety and accident reduction, insurance claim handling, and edge integration with Celantur's cutting-edge image and video anonymization solution."
    />

    <section
      className="container-fluid pt-8 pt-md-12 pb-8 pb-md-12 overlay overlay-black overlay-50 bg-cover text-center"
      style={{
        background: "url(/images/traffic.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <h1 className="display-2 font-weight-bold text-white" style={{ textShadow: "2px 2px 4px #000000" }}>
          Automated <span className="text-success">Blurring</span>
          <br />
          for Autonomous Driving
        </h1>
        <p className="lead mb-6 mb-md-8 text-gray-200" style={{ textShadow: "2px 2px 4px #000000" }}>
          High-quality and scalable image &amp; video anonymization for Autonomous Vehicles &amp; ADAS.
          <br />
          Automatically blur faces, bodies, license plates captured by front, rear and side cameras and dashcams.
        </p>
        <a href="https://app.celantur.com/" className="btn btn-success lift event-start-demo">
          Start Demo <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
        </a>
        <Link to="/contact/" className="btn btn-outline-light ml-3 lift event-contact">
          Contact Us
        </Link>
      </div>
    </section>

    <section className="py-8">
      <div className="container">
        <Customers />
      </div>
    </section>

    <section className="pt-5 pb-5">
      <div className="container">
        <FeatureObjectTypes />
      </div>
    </section>

 <section className="pt-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 mb-5 text-center">
            <h2 className="font-weight-bold">
              Automotive Privacy Challenges <span className="text-primary">easily solved</span>
            </h2>
            <p className="font-size-lg text-muted mb-6">
              By using Celantur, you can fulfill your customer's data protection demands while saving time and money.
            </p>
          </div>
        </div>
        <div className="row">
          <FeatureColumn headline="Quality" icon={faHandshake}>
            Industry-grade detection rate, trusted by global market leaders.
          </FeatureColumn>
          <FeatureColumn headline="Scalable and Fast" icon={faDatabase}>
            Handle massive datasets with ease.
          </FeatureColumn>
          <FeatureColumn headline="Easy Integration" icon={faCogs}>
            Many options to integrate Celantur into your data processing workflows.
          </FeatureColumn>
        </div>
        <div className="row">
          <FeatureColumn headline="GDPR-compliance" icon={faBalanceScale}>
            Comply with data protection laws around the globe, like the GDPR, CCPA, etc.
          </FeatureColumn>
          <FeatureColumn headline="Safe Data Sharing" icon={faLock}>
            Transfer data safely to third-parties or off-shore annotators.
          </FeatureColumn>
          <FeatureColumn headline="Save Costs" icon={faEuroSign}>
            Fully automated anonymization, no manual labor involved.
          </FeatureColumn>
        </div>
      </div>
    </section>

    <section className="py-9 bg-black bg-dotted">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 mb-5 text-center">
            <h2 className="font-weight-bold">
              <span className="font-gradient">Use-cases</span>
            </h2>
            <p className="font-size-lg text-muted mb-1">
              We help you making your use-case compliant with data protection laws.
            </p>
          </div>
        </div>
        <div className="row  mt-n7">
          <FeatureCard headline="ADAS Data Collection" icon={faCar}>
            Comply with privacy regulations while collecting data with your test vehicle fleet.
          </FeatureCard>
          <FeatureCard headline="Safety and accident reduction" icon={faRoadCircleExclamation}>
            Use videos to improve fleet operation safety while complying with data protection regulations.
          </FeatureCard>
          <FeatureCard headline="Insurance claim handling" icon={faCarCrash}>
            Provide video proof for insurance claims without privacy concerns.
          </FeatureCard>
          <FeatureCard headline="Edge Integration" icon={faMicrochip}>
            Anonymize videos directly after recording on an edge/IoT device.
          </FeatureCard>
          <FeatureCard headline="Share data across regions" icon={faEarthEurope}>
            Safely share data with RnD and annotation teams across regions.
          </FeatureCard>
        </div>
      </div>
    </section>

    <section className="py-8 py-md-11">
      <div className="container">
        <DeploymentOptions />
      </div>
    </section>

    <section className="my-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 text-center mb-5">
            <h2 className="font-weight-bold">Success Stories</h2>
            <p className="font-size-lg text-gray-700">
              Read how industry-leading companies use Celantur Container to solve their privacy challenges.
            </p>
          </div>
        </div>
        <div className="row">
          <SuccessStoryCard
            title={
              <>
                <mark>Fortune 500 tech company</mark> develops L4 self-driving vehicle technology in a GDPR-compliant
                manner
              </>
            }
            link="/success-story-fortune-500-self-driving/"
            image={data.traffic.childImageSharp.fixed}
          />
        </div>
      </div>
    </section>

    <Testimonials riegl={true} camenAI={true} />

    <Demo />

    <DataProtectionMeasures />
    
    <section className="py-8 py-md-11">
      <SeagateCard />
    </section>

    <WhyCelantur />

    <MoreBlogPosts caption="Latest Blog Posts about Automotive" posts={data} />
  </Layout>
)

export default Automotive

export const query = graphql`
  query AutomotiveBlogPosts {
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: ["automotive"] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            tags
            path
            teaser
            date
          }
        }
      }
    }
    traffic: file(relativePath: { eq: "traffic.jpg" }) {
      ...imageProductPage
    }
  }
`
